import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import TopTierBioBG from '../images/leadership/bio-bg-topTier.png';
import BioBG from '../images/leadership/bio-bg.png';

const BioPhotoModuleStyled = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex-shrink: 0.33;
    align-items: center;
    background: url('../images/leadership/bio-bg.png') no-repeat;
    width: ${props => (props.topTier ? '368px' : '275px')};
    margin-bottom: ${props => (props.topTier ? '48px' : '16px')};
    text-align: center;
    /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.25); */
    /* border-radius: 20px; */

    .bio__bg {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: auto;
        max-width: ${props => (props.topTier ? '388px' : '295px')};
        z-index: 1;
    }
    .bio__image {
        position: relative;
        width: 67%;
        height: ${props => (props.topTier ? '308px' : '230px')};
        border-radius: 20px;
        z-index: 8000;
    }

    .name-title {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%;
        height: 130px;

        .bio__name {
            position: relative;
            margin-top: 22px;
            width: 80%;
            font: ${props =>
                props.topTier
                    ? '700 24px purista-web, sans-serif'
                    : '700 18px purista-web, sans-serif'};
            font-style: italic;
            letter-spacing: ${props => (props.topTier ? '1.8px' : '1.35px')};
            text-transform: uppercase;
            z-index: 8000;
        }
        .bio__title {
            position: relative;
            width: 75%;
            margin-bottom: ${props => (props.topTier ? '80px' : '0px')};
            font: 400 12px roboto, sans-serif;
            text-transform: uppercase;
            letter-spacing: 0.86px;
            color: #6c7279;
            z-index: 8000;
        }
    }
`;

const BioPhotoModule = ({ name, title, title2, bioLink, source, topTier }) => (
    <Link to={bioLink}>
        <BioPhotoModuleStyled topTier={topTier}>
            <img
                className="bio__bg"
                src={topTier ? TopTierBioBG : BioBG}
                width={topTier ? '388' : '295'}
                height={topTier ? '388' : '320'}
                alt={`${name} bio background`}
            />
            <img
                className="bio__image"
                src={source}
                alt={`${name} bio photo`}
            />
            <div className="name-title">
                <p className="bio__name">{name}</p>
                <p className="bio__title">{title}</p>
                {title2 && <p className="bio__title">{title2}</p>}
            </div>
        </BioPhotoModuleStyled>
    </Link>
);

BioPhotoModule.propTypes = {
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    bioLink: PropTypes.string.isRequired,
    source: PropTypes.node.isRequired,
};

export default BioPhotoModule;
