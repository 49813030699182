import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import LeadershipStyled from '../components/page-styles/leadership-styles';

import BioPhotoModule from '../components/bioPhotoModule';

import {
        JamesHaslam,
        AdamWright,
        JuliusCox,
        BradJenkins,
        JoeLillo,
        MikeRodgers,
        DavidHughes,
        KristinSeabrook,
        BradAnderson,
} from '../images/leadership';

import heroImageDesktop from '../images/hero-leadership-desktop.png';
import Hero from '../components/hero';
import { withSize } from 'react-sizeme';

const LeadershipPage = ({ path, size }) => {
    return (
        <Layout path={path}>
            <LeadershipStyled>
                <SEO 
                    title="Pilot Flying J Corporate Structure and Executive Leadership" 
                    description= "Who owns Pilot Flying J? Jim Haslam founded Pilot Company, but it is ran by the Pilot Flying J Management Team and has a Pilot Flying J Corporate Structure."
                />
                <Hero
                    headerText="Pilot Company Leadership"
                    background={
                        size.width > 768 ? heroImageDesktop : heroImageDesktop
                    }
                    showLogo={false}
                    isSubPage={true}
                ></Hero>
                <section className="leaders">
                    <section className="top-tier">
                        <BioPhotoModule
                            name="James A. Haslam II"
                            title="Founder"
                            bioLink="/leadership/james-haslam"
                            source={JamesHaslam}
                            topTier="true"
                        />
                        <BioPhotoModule
                            name="Adam Wright"
                            title="Chief Executive Officer"
                            bioLink="/leadership/adam-wright"
                            source={AdamWright}
                            topTier="true"
                        />
                        <BioPhotoModule
                            name="Brad Anderson"
                            title="Executive Vice President, Chief Operating Officer"
                            bioLink="/leadership/brad-anderson"
                            source={BradAnderson}
                            topTier="true"
                        />
                    </section>
                    <section>
                    <BioPhotoModule
                            name="Julius Cox"
                            title="Executive Vice President, Chief People Officer"
                            bioLink="/leadership/julius-cox"
                            source={JuliusCox}
                        />
                        <BioPhotoModule
                            name="Brad Jenkins"
                            title="President, PFJ Energy"
                            bioLink="/leadership/brad-jenkins"
                            source={BradJenkins}
                        />
                        <BioPhotoModule
                            name="Joe Lillo"
                            title="Executive Vice President, Chief Financial Officer"
                            bioLink="/leadership/joe-lillo"
                            source={JoeLillo}
                        />
                        <BioPhotoModule
                            name="Mike Rodgers"
                            title="Executive Vice President, Chief Technology Officer"
                            bioLink="/leadership/mike-rodgers"
                            source={MikeRodgers}
                        />
                    </section>
                    <section>
                        <BioPhotoModule
                            name="David Hughes"
                            title="Senior Vice President, Sales"
                            bioLink="/leadership/david-hughes"
                            source={DavidHughes}
                        />
                        <BioPhotoModule
                            name="Kristin Seabrook"
                            title="Senior Vice President, Chief Legal Counsel"
                            bioLink="/leadership/kristin-seabrook"
                            source={KristinSeabrook}
                        />
                        
                                            
                    </section>
                </section>
            </LeadershipStyled>
        </Layout>
    );
};

export default withSize()(LeadershipPage);
