import styled from 'styled-components';

const LeadershipStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    .leaders {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 1108px;
        margin-top: 48px;

        section {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
        }

        a {
            text-decoration: none;
            color: #0b1822;
        }
    }
`;

export default LeadershipStyled;
